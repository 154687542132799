<template>
  <v-form autocomplete="off" @submit.prevent="createUser">
    <v-text-field v-model="user.email" label="Email" required />
    <v-text-field v-model="user.password" label="Пароль" required />
    <v-text-field v-model="user.passwordRepeat" label="Пароль еще раз" required />

    <v-text-field v-model="user.name" label="Имя" />
    <v-text-field v-model="user.lastName" label="Фамилия" />

    <v-select v-model="user.role" :items="userRoles" label="Роль" item-value="uid" item-text="text" />

    <v-text-field v-model="boundIPsProxy" label="IP-адреса" />

    <div class="mt-4 text-right">
      <v-btn class="mr-4" @click="clear">Сброс</v-btn>
      <v-btn type="submit" :loading="loading" color="accent">Создать</v-btn>
    </div>
  </v-form>
</template>

<script>
import { stringToMultipleFilter } from "~/core/helpers";
import { ApiValidationError } from "~/core/api-validation-error";

import { getUserRoleMap } from "~/libs/user/user-role";

const createUserDto = {
  email: "",
  password: "",
  passwordRepeat: "",

  name: "",
  lastName: "",

  role: "",
  comment: "",

  boundIPs: []
};

Object.freeze(createUserDto);

export default {
  data() {
    return {
      loading: false,

      userRoles: getUserRoleMap(),

      user: { ...createUserDto }
    };
  },
  computed: {
    boundIPsProxy: {
      get() {
        return this.user.boundIPs.join(", ");
      },
      set(value) {
        this.user.boundIPs = stringToMultipleFilter(value);
      }
    }
  },
  methods: {
    async createUser() {
      this.loading = true;

      try {
        await this.$axios.$post("users", this.user);

        this.clear();

        this.$snackbar.success("Пользователь создан");
      } catch (e) {
        const html = new ApiValidationError(e, Object.keys(this.user)).toHtml();

        this.$snackbar.error("Не удалось создать пользователя: " + html);
      } finally {
        this.loading = false;
      }
    },

    clear() {
      this.user = { ...createUserDto };
    }
  }
};
</script>
